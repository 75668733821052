import Can from "components/Can"
import PageHeader from "components/PageHeader"
import Schedule from "components/Schedule"
import ProductCards from "components/ProductCards"

import { ITEMS } from "./constants"
import "./Rede.styl"

const Rede = ({ pageContext: { business } }) => {
  return (
    <Can I="management" the="rede">
      <PageHeader title="Rede" variant="medium" />
      <section className="rede">
        <Schedule businessId={business.business_id} />
        <ProductCards items={ITEMS} />
      </section>
    </Can>
  )
}

export default Rede
