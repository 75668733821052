import auth from "utils/auth"

/**
 * List of ITEMS used to create ProductCards
 *
 * This list can be different in jest environment because is mocked.
 */
export const ITEMS = [
  {
    title: "Clientes Potenciais",
    icon: "phone-book",
    profiles: [
      {
        profile: "master",
        type: "link",
        link: ROUTES.redePotentialCustomersCompanies.path,
      },
      {
        profile: "admin",
        type: "link",
        link: `${ROUTES.redePotentialCustomersCompany.path}?group_id=${
          auth.getUser()?.group_id
        }`,
      },
      {
        profile: "salesman",
        type: "link",
        link: ROUTES.redePotentialCustomers.path,
      },
    ],
  },
]
